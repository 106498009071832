
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.content-with-image-container-preview {
    display: flex;
    justify-content: space-between;
    @include handleAllBreakpoints("gap", "gap", "3.5rem");
    @include handleAllBreakpoints("flex-direction", "imagePlacement", "column::row");
    @include handleAllBreakpoints("align-items", "verticalAlign", "center");

    div.contentContainer {
        position: relative;
        @include handleAllBreakpoints("margin", "contentMargin", "unset");
        .text-block {
            @include handleTextBlockVariables();
        }
        @include handleAllBreakpoints("width", "contentWidth", "100%");
        @include handleAllBreakpoints("max-width", "contentMaxWidth", "unset");
    }

    div.imageContainer {
        position: relative;
        @include handleAllBreakpoints("width", "imageWidth", "100%");
        @include handleAllBreakpoints("height", "imageHeight", "100%");
        @include handleAllBreakpoints("min-width", "imageMinWidth", "unset");
        @include handleAllBreakpoints("min-height", "imageMinHeight", "unset");
        @include handleAllBreakpoints("margin", "imageMargin", "unset");
        img {
            width: 100%;
            height: 100%;
        }
    }

    // btn style
    .scrollToTopPreview {
        display: block;
        border-radius: 0.5rem;
        // font-weight: 600;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;

        @include handleAllBreakpoints("color", "buttonTextColor", "var(--global-sections-buttons-text-color, white)");
        @include handleAllBreakpoints("font-size", "buttonTextSize", "18px");
        @include handleAllBreakpoints("font-weight", "buttonTextWeight", "600");
        @include handleAllBreakpoints(
            "background",
            "buttonBackgroundColor",
            "var(--global-sections-buttons-background-color, black)"
        );
        @include handleAllBreakpoints(
            "border-radius",
            "buttonBorderRadius",
            "var(--global-sections-buttons-border-radius, 4px)"
        );
        @include handleAllBreakpoints("border", "buttonBorder", "none");
        @include handleAllBreakpoints("box-shadow", "buttonBoxShadow", "none");
        @include handleAllBreakpoints("min-width", "buttonMinWidth", "unset");
        @include handleAllBreakpoints("padding", "buttonPadding", "1rem");
        @include handleAllBreakpoints("margin", "buttonMargin", "1rem auto");

        &:hover {
            @include handleAllBreakpoints(
                "color",
                "hoverTextColor",
                "var(--global-sections-buttons-text-color, white)"
            );
            @include handleAllBreakpoints(
                "background",
                "hoverButtonBackgroundColor",
                "var(--global-sections-buttons-background-color, black)"
            );
            @include handleAllBreakpoints("border", "hoverButtonBorder", "var(--global-sections-buttons-border, none)");
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 300ms;
            transform: translateY(-0.5rem);
        }

        @media (min-width: 640px) {
            padding-left: 2.75rem;
            padding-right: 2.75rem;
        }
    }
}
